import { useCallback, useEffect, useRef, useState } from 'react';

import { trackInteraction } from '@/utils/analytics';

/*
 * Remove inline STYLE attributes and disable STYLE and LINK elements
 */
const destyleMktoForm = (mktoForm, testMode) => {
    const formEl = mktoForm.getFormElem()[0];
    const mktoForms2BaseStyle = document.querySelector('#mktoForms2BaseStyle');
    const mktoForms2ThemeStyle = document.querySelector('#mktoForms2ThemeStyle');
    const formElementId = formEl?.getAttribute('data-form-id');

    // remove inline styles from <form> and children
    Array.from(formEl.querySelectorAll('[style]'))
        .concat(formEl)
        .forEach((el) => {
            el.removeAttribute('style');

            //make for, and id attributes unique
            if (el.hasAttribute('id') && el.tagName !== 'FORM') {
                el.setAttribute('id', `${el.getAttribute('id')}_${formElementId}`);
            }

            if (el.tagName === 'LABEL') {
                const targetId = el.getAttribute('for');
                if (targetId && formEl.querySelector(`#${targetId}`)) {
                    el.setAttribute('for', `${el.getAttribute('for')}_${formElementId}`);
                    if (testMode && el.textContent?.length <= 1) {
                        el.innerHTML = el.innerHTML + 'Label';
                    }
                } else {
                    el.classList.add('redundant-label');
                }
            }
        });

    // Disable form related <style /> and <link />
    const styleSheets = Array.from(document.styleSheets);
    styleSheets.forEach((ss) => {
        if (
            [mktoForms2BaseStyle, mktoForms2ThemeStyle].indexOf(ss.ownerNode) !== -1 ||
            formEl.contains(ss.ownerNode)
        ) {
            ss.disabled = true;
        }
    });

    const formControlSelectors = [
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="date"]',
        'input[type="tel"]',
        'input[type="url"]',
        'textarea',
    ];

    Array.from(formEl.querySelectorAll(formControlSelectors.join(', '))).forEach((el) => {
        el?.classList?.add('typeField');
        const fieldRow = el.closest('.mktoFormRow');
        const fieldWrap = el.closest('.mktoFieldWrap');
        fieldRow?.classList?.add('typeFieldRow');
        fieldWrap?.classList?.add('typeFieldWrap');
        const labelEl = fieldWrap?.querySelector('.mktoLabel');
        const labelText = labelEl?.textContent;

        if (labelText?.length > 1) {
            el?.classList?.add('hasLabel');
            el?.setAttribute('placeholder', '');
        }

        el.addEventListener('input', (event) => {
            event.target.setAttribute('data-input-value', event.target.value);
            if (event.target.value) {
                fieldWrap?.classList?.add('active');
            } else {
                fieldWrap?.classList?.remove('active');
            }
        });

        if (el?.classList?.contains('mktoInputMask')) {
            el.addEventListener('focus', (event) => {
                setTimeout(() => {
                    if (event.target.value) {
                        fieldWrap?.classList?.add('active');
                    }
                }, 100);
            });
            el.addEventListener('blur', (event) => {
                setTimeout(() => {
                    if (!event.target.value) {
                        fieldWrap?.classList?.remove('active');
                    }
                }, 200);
            });
        }
        if (el?.type === 'date') {
            el.addEventListener('focus', (event) => {
                fieldWrap?.classList?.add('focus');
            });
            el.addEventListener('blur', (event) => {
                fieldWrap?.classList?.remove('focus');
            });
        }
    });

    Array.from(formEl.querySelectorAll('select')).forEach((el) => {
        el?.classList?.add('typeField');
        const fieldRow = el.closest('.mktoFormRow');
        const fieldWrap = el.closest('.mktoFieldWrap');
        fieldRow?.classList?.add('typeFieldRow');
        fieldWrap?.classList?.add('typeFieldWrap');
        const labelText = fieldWrap?.querySelector('.mktoLabel')?.textContent;
        if (labelText?.length > 1) {
            const options = Array.from(el.options);
            options.forEach((option) => {
                if (!option.value) {
                    option.textContent = '';
                }
            });
            el?.classList?.add('hasLabel');
        }
        el.addEventListener('change', (event) => {
            if (event.target.value) {
                fieldWrap?.classList?.add('active');
            } else {
                fieldWrap?.classList?.remove('active');
            }
        });
    });

    /** Remove instruction <span /> */
    Array.from(formEl.querySelectorAll('.mktoInstruction')).forEach((element) => {
        element.remove();
    });

    formEl.setAttribute('data-styles-ready', 'true');
};

const useMarketo = ({ baseUrl, munchkinId, formId, callbacks, analytics, testMode, formType }) => {
    const [scriptAdded, setScriptAdded] = useState(false);
    const [formLoaded, setFormLoaded] = useState(false);
    const formRef = useRef({ buttonLabel: '' });

    const formCallback = useCallback(
        (form) => {
            // Add an onSuccess handler
            form.onSuccess((values, followUpUrl) => {
                callbacks?.onSuccess?.(values, followUpUrl);

                // analytics
                if (analytics) {
                    trackInteraction({
                        componentName: 'Form',
                        interactionType: 'primary_cta',
                        actionLabel: formRef?.current.buttonLabel,
                        selector: analytics?.selector || 'button',
                        ...analytics,
                    });
                }
                return false;
            });
            form.onSubmit(() => {
                callbacks?.onSubmit?.();
            });
        },
        [callbacks, analytics],
    );

    useEffect(() => {
        if (scriptAdded) {
            if (!formLoaded) {
                window?.MktoForms2?.loadForm(baseUrl, munchkinId, formId, formCallback);
                setFormLoaded(true);

                window?.MktoForms2?.whenRendered((form) => {
                    const submitButton = form
                        .getFormElem()[0]
                        ?.querySelector('.mktoButton[type="submit"]');

                    formRef.current.buttonLabel = submitButton?.textContent;
                    destyleMktoForm(form, testMode);

                    // analytics
                    if (analytics) {
                        submitButton?.setAttribute('data-trigger', analytics?.selector || 'button');
                    }
                    if (formType === 'contactus') {
                        form.getFormElem()[0]?.querySelector('#form_ShowSubmit')?.remove();
                        submitButton.style.display = 'none';
                        const primaryCategory_field = form
                            .getFormElem()[0]
                            .querySelector(`[name="form_PrimaryCategory"]`);
                        const secondaryCategory_field = form
                            .getFormElem()[0]
                            .querySelector(`[name="form_SecondaryCategory"]`);
                        form.getFormElem().change(function () {
                            if (primaryCategory_field) {
                                const primaryValue = primaryCategory_field.value;
                                const secondaryValue = secondaryCategory_field?.value;

                                let showSubmit = false;

                                if (primaryValue === 'General Inquiries') {
                                    if (secondaryValue && secondaryValue !== 'Other') {
                                        showSubmit = true;
                                    }
                                } else if (
                                    (primaryValue && !secondaryCategory_field) ||
                                    (primaryValue && secondaryCategory_field && secondaryValue)
                                ) {
                                    showSubmit = true;
                                }

                                submitButton.style.display = showSubmit ? 'inline-block' : 'none';
                            }
                        });
                    }
                });

                window?.MktoForms2?.whenReady((form) => {
                    const formEl = form.getFormElem()[0];
                    formEl.id = '';

                    if (formType === 'contactus') {
                        const params = new URLSearchParams(window?.location?.search);

                        const pc = params.get('pc');
                        const sc = params.get('sc');
                        const updateDropdown = (fieldName, value) => {
                            if (value) {
                                const dropdown = form
                                    .getFormElem()[0]
                                    .querySelector(`[name="${fieldName}"]`);
                                if (dropdown) {
                                    dropdown.value = value;
                                    const event = new Event('change', { bubbles: true });
                                    dropdown.dispatchEvent(event);
                                }
                            }
                        };
                        updateDropdown('form_PrimaryCategory', pc);
                        updateDropdown('form_SecondaryCategory', sc);
                    }
                });
            }
        } else {
            if (window?.MktoForms2) {
                setScriptAdded(true);
            } else {
                const script = document.createElement('script');
                script.defer = true;
                script.onload = () => (window?.MktoForms2 ? setScriptAdded(true) : null);
                script.src = `${baseUrl}/js/forms2/js/forms2.min.js`;
                document.head.appendChild(script);
            }
        }
    }, [
        scriptAdded,
        baseUrl,
        formId,
        formLoaded,
        munchkinId,
        formCallback,
        analytics,
        testMode,
        formType,
    ]);
};

export default useMarketo;
